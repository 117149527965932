// src/Components/Address.js
import React, { useState, useEffect } from 'react';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../firebase/firestore';
import { useUser } from '../context/userContext';
import { IoCheckmarkCircle, IoCloseCircle } from 'react-icons/io5';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import bs58check from 'bs58check';

function isValidTronAddress(address) {
  try {
    const decoded = bs58check.decode(address);
    // Tron addresses start with 0x41 after decoding
    return decoded.length === 21 && decoded[0] === 0x41;
  } catch (error) {
    return false;
  }
}

export const Address = () => {
  const { id, isAddressSaved, walletAddress, setWalletAddress, setIsAddressSaved } = useUser();
  const [tronAddress, setTronAddress] = useState('');
  const [isValidAddress, setIsValidAddress] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchAddress = async () => {
      const userRef = doc(db, 'telegramUsers', id.toString());
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        const data = userDoc.data();
        if (data.address) {
          setTronAddress(data.address);
          setIsValidAddress(isValidTronAddress(data.address));
          setWalletAddress(data.address);
          setIsAddressSaved(true);
        }
      }
    };
    fetchAddress();
  }, [id, setWalletAddress, setIsAddressSaved]);

  const handleAddressChange = (e) => {
    const address = e.target.value.trim();
    setTronAddress(address);
    setIsValidAddress(isValidTronAddress(address));
  };

  const saveAddressToFirestore = async () => {
    if (isValidAddress) {
      const userRef = doc(db, 'telegramUsers', id.toString());
      try {
        await updateDoc(userRef, {
          address: tronAddress,
          isAddressSaved: true,
        });
        setIsAddressSaved(true);
        setWalletAddress(tronAddress);
        setShowModal(false);
        console.log('Address saved successfully');
      } catch (error) {
        console.error('Error saving address:', error);
      }
    } else {
      alert('Invalid Tron address');
    }
  };

  const handleDisconnect = async () => {
    const userRef = doc(db, 'telegramUsers', id.toString());
    try {
      await updateDoc(userRef, {
        address: '',
        isAddressSaved: false,
      });
      setIsAddressSaved(false);
      setWalletAddress('');
      setTronAddress('');
      setIsValidAddress(false);
      setShowModal(false);
      console.log('Address disconnected successfully');
    } catch (error) {
      console.error('Error deleting address:', error);
    }
  };

  return (
    <>
      <div className="w-full flex justify-end">
        {isAddressSaved ? (
          <button
            onClick={() => setShowModal(true)}
            className="bg-[#a4a4a424] flex items-center py-2 px-3 rounded-full space-x-1"
          >
            <img src="/wallet.webp" alt="wallet" className="w-4" />
            <div className="text-sm text-white">
              <h4 className="font-semibold">Wallet is connected</h4>
            </div>
            <IoCheckmarkCircle size={20} className="text-[#40863d]" />
          </button>
        ) : (
          <button
            onClick={() => setShowModal(true)}
            className="bg-[#319cdf] flex items-center py-2 px-3 rounded-full space-x-1"
          >
            <img src="/wallet.webp" alt="wallet" className="w-4" />
            <div className="text-sm text-white">Connect your wallet</div>
            <MdOutlineKeyboardArrowRight size={20} className="text-white" />
          </button>
        )}
      </div>

      {showModal && (
        <div className="fixed inset-0 z-40 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-modal rounded-2xl p-6 relative w-11/12 max-w-md">
            <button
              onClick={() => setShowModal(false)}
              className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
            >
              <IoCloseCircle size={32} />
            </button>
            {isAddressSaved ? (
              <div className="text-center">
                <h3 className="text-xl font-semibold text-green-500 mb-2">Your wallet is connected</h3>
                <p className="text-gray-300 mb-6">Wallet: {walletAddress}</p>
                <p className="text-gray-300 mb-6">You can disconnect your wallet if you wish.</p>
                <button onClick={handleDisconnect} className="bg-red-500 text-white py-2 px-4 rounded-full">
                  Disconnect Wallet
                </button>
              </div>
            ) : (
              <div className="text-center">
                <h3 className="text-xl font-semibold mb-2">Connect your Tron wallet</h3>
                <p className="text-gray-300 mb-6">Please enter your Tron wallet address to connect.</p>
                <input
                  type="text"
                  placeholder="Enter your Tron wallet address"
                  value={tronAddress}
                  onChange={handleAddressChange}
                  className="w-full px-4 py-2 mb-4 border rounded-md text-black"
                />
                {!isValidAddress && tronAddress && <p className="text-red-500 mb-4">Invalid Tron address</p>}
                <button
                  onClick={saveAddressToFirestore}
                  disabled={!isValidAddress}
                  className={`${isValidAddress ? 'bg-blue-500' : 'bg-gray-500'} text-white py-2 px-4 rounded-full`}
                >
                  Connect Wallet
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Address;
