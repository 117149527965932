// src/Components/Specials.js
import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { useUser } from '../context/userContext';
import { doc, updateDoc, arrayUnion } from 'firebase/firestore';
import { db } from '../firebase/firestore';
import { IoCheckmarkCircle, IoCheckmarkCircleSharp, IoClose } from 'react-icons/io5';
import { AiTwotoneInfoCircle } from 'react-icons/ai';
import { QRCodeCanvas } from 'qrcode.react';
import bs58check from 'bs58check';

function tronBase58ToHex(address) {
  try {
    const decoded = bs58check.decode(address);
    return '0x' + decoded.toString('hex');
  } catch (error) {
    return null;
  }
}

const Specials = () => {
  const { id, purchasedCards, specialCards, setPurchasedCards } = useUser();
  const [selectedCard, setSelectedCard] = useState(null);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [transactionId, setTransactionId] = useState('');
  const [verificationMessage, setVerificationMessage] = useState('');
  const [verificationStatus, setVerificationStatus] = useState('');
  const [congratsMessage, setCongratsMessage] = useState('');
  const [showCongratsModal, setShowCongratsModal] = useState(false);
  const infoRefTwo = useRef(null);

  const YOUR_RECEIVING_ADDRESS = 'YOUR_BASE58_RECEIVING_ADDRESS'; // Replace with your address
  const expectedAddressHex = tronBase58ToHex(YOUR_RECEIVING_ADDRESS);

  const handlePurchaseClick = (card) => {
    setSelectedCard(card);
    setShowPaymentModal(true);
  };

  const verifyTransaction = async () => {
    try {
      const response = await axios.get(`https://api.trongrid.io/wallet/gettransactionbyid`, {
        params: {
          value: transactionId,
        },
      });

      const tx = response.data;

      if (tx && tx.raw_data) {
        const contract = tx.raw_data.contract[0];
        const txAmount = contract.parameter.value.amount;
        const txToAddressHex = '0x' + contract.parameter.value.to_address;

        const expectedAmount = parseInt(selectedCard.cost * 1e6); // TRX uses 6 decimals

        if (txToAddressHex.toLowerCase() === expectedAddressHex.toLowerCase() && txAmount >= expectedAmount) {
          // Transaction is valid
          // Update Firestore
          const userRef = doc(db, 'telegramUsers', id.toString());
          await updateDoc(userRef, {
            specialCards: arrayUnion(selectedCard),
          });
          setPurchasedCards((prev) => [...prev, selectedCard]);
          setVerificationStatus('success');
          setVerificationMessage('Payment verified successfully!');
          setShowPaymentModal(false);

          setCongratsMessage(
            <div className="w-full flex justify-center flex-col items-center space-y-3">
              <div className="w-full items-center justify-center flex flex-col space-y-2">
                <IoCheckmarkCircleSharp size={32} className="text-accent" />
                <p className="font-medium text-center">Congratulations!</p>
              </div>
              <span className="font-medium text-[20px] text-[#ffffff] pt-2 pb-2 flex items-center space-x-1">
                <img src="/trx.png" alt="TRX" className="w-[22px]" />
                <span className="text-accent">{selectedCard.profit}</span> <span>TRX CLAIMED</span>
              </span>
              <p className="pb-6 text-[15px] w-full text-center">
                Your claimed TRX will be kept safe in your wallet till token launch. You can also swap your TRX for more
                APEX tokens or other listed assets in the wallets menu.
              </p>
            </div>
          );
          setShowCongratsModal(true);
        } else {
          setVerificationStatus('error');
          setVerificationMessage('Transaction details do not match.');
        }
      } else {
        setVerificationStatus('error');
        setVerificationMessage('Transaction not found.');
      }
    } catch (error) {
      console.error('Error verifying transaction:', error);
      setVerificationStatus('error');
      setVerificationMessage('An error occurred while verifying the transaction.');
    }
  };

  const handleClickOutside = (event) => {
    if (infoRefTwo.current && !infoRefTwo.current.contains(event.target)) {
      setShowPaymentModal(false);
    }
  };

  useEffect(() => {
    if (showPaymentModal) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showPaymentModal]);

  const formatNumber = (num) => {
    if (num < 1000) {
      return num;
    } else if (num < 1000000) {
      return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'k';
    } else {
      return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
    }
  };

  return (
    <>
      {/* Render special cards */}
      {specialCards.map((card, index) => {
        const isPurchased = purchasedCards.some((purchasedCard) => purchasedCard.title === card.title);

        return (
          <button
            onClick={() => {
              if (!isPurchased) {
                setSelectedCard(card);
                setShowPaymentModal(true);
              }
            }}
            key={index}
            className={`${card.class} w-[48%] py-3 relative rounded-[15px] [&:nth-child(2)]:!mt-0 text-[15px] flex flex-col items-center`}
            disabled={isPurchased}
            style={{
              opacity: isPurchased ? 0.5 : 1,
              cursor: isPurchased ? 'not-allowed' : 'pointer',
            }}
          >
            <div className="w-[60%] pt-2 rounded-[4px]">
              <img src={card.icon} alt={`${card.title} icon`} className="w-full rounded-[8px] object-cover h-[60px]" />
            </div>

            <h2 className="pt-1 font-medium">{card.title}</h2>
            <p className="text-[12px] text-secondary">{card.tagline}</p>

            <div className="flex items-center space-x-1 pt-1">
              <span className="text-[10px]">Profit</span>
              <img src="/trx.png" alt="coin" className="w-[12px]" />
              <span className="text-[12px] font-semibold">{formatNumber(card.profit)} TRX</span>
            </div>
            <div className="w-[80%] h-[1px] bg-[#A5A5A529] mt-[10px]" />

            <div className="flex items-center justify-center px-3 text-[14px] text-secondary font-semibold py-[6px]">
              <span className="flex items-center space-x-2">
                <img src="/trx.png" alt="coin" className="w-[16px]" />
                <span className="">{card.cost} TRX</span>
              </span>
            </div>
            {isPurchased && (
              <div
                className={`absolute p${card.class} rounded-[15px] left-0 right-0 top-0 bottom-0 flex justify-center flex-col items-center text-center`}
              >
                <IoCheckmarkCircle size={40} className="text-green-500" />
                <h2 className="font-medium text-[13px] text-white px-4">You now own this special card 😎</h2>
              </div>
            )}
          </button>
        );
      })}

      {/* Payment Modal */}
      {showPaymentModal && selectedCard && (
        <div className="fixed flex bottom-0 left-0 z-40 right-0 h-[100vh] bg-[#303030c4] flex-col justify-end items-center">
          <div
            ref={infoRefTwo}
            className="w-full bg-divider shadowtop rounded-tl-[40px] rounded-tr-[40px] relative flex flex-col ease-in duration-300 transition-all justify-center"
          >
            <div className="w-full flex taskbg rounded-tl-[40px] rounded-tr-[40px] mt-[2px] justify-center relative flex-col items-center space-y-3 p-4 pt-20 pb-10">
              <button
                onClick={() => setShowPaymentModal(false)}
                className="flex items-center justify-center h-[32px] w-[32px] rounded-full bg-[#383838] absolute right-6 top-4 text-center font-medium text-[16px]"
              >
                <IoClose size={20} className="text-[#9995a4]" />
              </button>

              <div className="w-full flex justify-center flex-col items-center">
                <div className="w-[80px] h-[80px] rounded-[25px] flex items-center justify-center">
                  <AiTwotoneInfoCircle size={80} className="" />
                </div>
                <h3 className="font-semibold text-[32px]">{selectedCard.title}</h3>
                <p className="pb-6 text-primary text-[14px] px-4 text-center">{selectedCard.description}</p>
                <div className="pb-1 text-primary flex items-center justify-center w-full space-x-1 font-semibold text-[15px] px-4 text-center">
                  <span> Price:</span>{' '}
                  <span className="pl-1">
                    <img src="/trx.png" alt="TRX" className="w-[14px] h-[14px]" />
                  </span>{' '}
                  <span>{selectedCard.cost}</span> <span> TRX</span>
                </div>
                <div className="pb-6 text-primary flex items-center justify-center w-full space-x-1 font-semibold text-[15px] px-4 text-center">
                  <span> Profit:</span>{' '}
                  <span className="pl-1">
                    <img src="/trx.png" alt="TRX" className="w-[14px] h-[14px]" />
                  </span>{' '}
                  <span className="text-green-500 ">+{selectedCard.profit} TRX</span>
                </div>
              </div>

              <p className="pb-6 text-primary text-[14px] px-4 text-center">
                Please send <strong>{selectedCard.cost} TRX</strong> to the address below:
              </p>
              <p className="address">{YOUR_RECEIVING_ADDRESS}</p>
              <div className="w-full flex justify-center items-center">
                <QRCodeCanvas value={`tron:${YOUR_RECEIVING_ADDRESS}?amount=${selectedCard.cost}`} />
              </div>
              <p className="pb-6 text-primary text-[14px] px-4 text-center">
                After sending the payment, enter the transaction ID below:
              </p>
              <input
                type="text"
                value={transactionId}
                onChange={(e) => setTransactionId(e.target.value)}
                placeholder="Transaction ID"
                className="input-field w-full py-2 px-4 mb-4 border rounded"
              />
              <button
                onClick={verifyTransaction}
                className="bg-btn4 w-full py-3 text-center rounded-[12px] font-semibold text-[17px]"
              >
                Verify Payment
              </button>
              {verificationMessage && (
                <p
                  className={`message ${verificationStatus} mt-4`}
                  style={{ color: verificationStatus === 'success' ? 'green' : 'red' }}
                >
                  {verificationMessage}
                </p>
              )}
            </div>
          </div>
        </div>
      )}

      {/* Congrats Modal */}
      <div
        className={`${
          showCongratsModal === true ? 'visible' : 'invisible'
        } fixed top-[-12px] bottom-0 left-0 z-40 right-0 h-[100vh] bg-[#00000042] flex justify-center items-center backdrop-blur-[6px] px-4`}
      >
        <div
          className={`${
            showCongratsModal === true ? 'opacity-100 mt-0 ease-in duration-300' : 'opacity-0 mt-[100px]'
          } w-full bg-modal relative rounded-[16px] flex flex-col justify-center p-8`}
        >
          {congratsMessage}

          <div className="w-full flex justify-center">
            <button
              onClick={() => setShowCongratsModal(false)}
              className={`bg-btn4 w-full py-[16px] px-6 flex items-center justify-center text-center rounded-[12px] font-medium text-[16px]`}
            >
              Continue mining
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Specials;
